<template>
  <div>
    <div class="home">
      <MenuTop />
      <div class="aa">
        <!-- 轮播图 -->
        <el-carousel :interval="5000" arrow="never" height="200px">
          <el-carousel-item v-for="item in 4" :key="item">
            <div class="item_box" v-if="item == 1">
              <div class="item">
                <!-- <p class="text">BUSINESS&nbsp;&nbsp;LOAN</p> -->
                <!-- <button>LOAN</button> -->
              </div>
              <img class="imgs" src="../../public/11.jpg" alt />
            </div>
            <div class="item_box" v-if="item == 2">
              <div class="item">
                <!-- <p class="text">BUSINESS&nbsp;&nbsp;LOAN</p> -->
                <!-- <button>LOAN</button>  -->
              </div>
              <img class="imgs" src="../../public/22.jpg" alt />
            </div>
            <div class="item_box" v-if="item == 3">
              <div class="item">
                <!-- <p class="text">BUSINESS&nbsp;&nbsp;LOAN</p> -->
                <!-- <button>LOAN</button> -->
              </div>
              <img class="imgs" src="../../public/33.jpg" alt />
            </div>
            <div class="item_box" v-if="item == 4">
              <div class="item">
                <!-- <p class="text">BUSINESS&nbsp;&nbsp;LOAN</p> -->
                <!-- <button>LOAN</button> -->
              </div>
              <img class="imgs" src="../../public/44.jpg" alt />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- <div class="box-info">
       <svg
          t="1709455555700"
          class="icon icon-notice"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="8498"
          width="32"
          height="32"
        >
          <path
            d="M643.072 97.28c-20.48-12.8-45.568-14.848-70.656-3.072l-322.56 188.928h-158.72c-36.864 0-67.072 30.208-67.072 67.072v340.48c0 36.864 30.208 67.072 67.072 67.072h158.72l325.12 190.464c9.216 4.608 19.456 6.656 29.696 6.656 13.312 0 26.624-3.584 37.888-11.264 20.48-12.8 32.768-35.328 32.768-59.392v-727.04c0-24.576-12.288-47.104-32.256-59.904zM225.28 689.664l-133.12 1.024-1.024-338.944H225.28v337.92z m381.44 197.632l-313.344-183.808V336.896l312.32-182.784 1.536 730.112-0.512 3.072z m389.12-377.344c0 18.944-15.36 34.304-34.304 34.304h-155.648a34.304 34.304 0 0 1 0-68.608h155.648c18.944 0 34.304 15.36 34.304 34.304z m-218.624-174.08c-10.24-15.872-6.144-36.864 9.216-47.104L921.6 198.656c15.872-10.752 36.864-6.144 47.104 9.216 10.24 15.872 6.144 36.864-9.216 47.104L824.32 345.088c-5.632 4.096-12.288 5.632-18.944 5.632-10.752 0.512-21.504-4.608-28.16-14.848z m182.272 428.544c15.872 10.24 19.968 31.744 9.216 47.104-6.656 9.728-17.408 15.36-28.672 15.36-6.656 0-13.312-2.048-18.944-5.632l-135.168-90.112c-15.872-10.24-19.968-31.744-9.216-47.104 10.24-15.872 31.744-19.968 47.104-9.216l135.68 89.6z"
            p-id="8499"
            fill="#00264b"
          />
        </svg> 
      <div class="notice">
          <div class="notice__inner">
            <div class="notice__item" style="font-weight:700;font-size:20px">
              <span style="color:#A46F06">974*****699</span> successful borrowing
              <span style="color:#A46F06">₱2729065</span>!
            </div>
            <div class="notice__item" style="font-weight:700;font-size:20px">
              <span style="color:#A46F06">090*****314</span> successful borrowing
              <span style="color:#A46F06">₱1853670</span>!
            </div>
            <div class="notice__item" style="font-weight:700;font-size:20px">
              <span style="color:#A46F06">080*****220</span> successful borrowing
              <span style="color:#A46F06">₱169836</span>!
            </div>
            <div class="notice__item" style="font-weight:700;font-size:20px">
              <span style="color:#A46F06">054****142</span> successful borrowing
              <span style="color:#A46F06">₱1867243</span>!
            </div>
            <div class="notice__item" style="font-weight:700;font-size:20px">
              <span style="color:#A46F06">099*****850</span> successful borrowing
              <span style="color:#A46F06">₱2865021</span>!
            </div>
            <div class="notice__item" style="font-weight:700;font-size:20px">
              <span style="color:#A46F06">091*****667</span> successful borrowing
              <span style="color:#A46F06">₱6523902</span>!
            </div>
          </div>
        </div> 
      </div> -->
      <!--  -->
      <div class="apply">
        <div class="img-box">
          <div class="imgs-0">
            <img class="img-1" src="../../public/home/t4.png" alt />
            <br>
            <span>Submit Application</span>
          </div>

          <div class="imgs-2">
            <img class="img2" src="../../public/home/t2.png" alt />
          </div>

          <div class="imgs-0">
            <img class="img-1" src="../../public/home/t3.png" alt />
            <br>
            <span>Get Guota</span>
          </div>

          <div class="imgs-2">
            <img class="img2" src="../../public/home/t2.png" alt />
          </div>

          <div class="imgs-0">
            <img class="img-1" src="../../public/home/t1.png" alt />
            <br>
            <span>Loan Now</span>
          </div>
        </div>
        <!-- <h1>
          <p style="width:100%;font-size:40px">Apply For A Loan</p>
        </h1> -->

        <div class="apply-1">
          <div>
            <small style="width:100%;font-size:25px;font-weight:700;color: #1A71FA">Minimum Borrowing</small>
            <p style="font-weight:700;font-size:28px">{{ limitInfo.limitmin }}₱</p>
          </div>
          <div class="line"></div>
          <div>
            <small style="width:100%;font-size:25px;font-weight:700;color: #1A71FA">Maximum Borrowing</small>
            <p style="font-weight: 700;font-size:28px">{{ limitInfo.limitmax }}₱</p>
          </div>
        </div>
      </div>

      <div class="loan">
        <div class="loan-left">
          <div class="loan-left-line"></div>
          <svg t="1709439274416" class="icon icon1" viewBox="0 0 1194 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="5234" width="30" height="30">
            <path
              d="M597.333333 1024a512 512 0 1 1 512-512 512 512 0 0 1-512 512z m0-914.2784A402.2784 402.2784 0 1 0 999.611733 512 402.2784 402.2784 0 0 0 597.333333 109.7216zM597.333333 768a256 256 0 1 1 256-256 256 256 0 0 1-256 256z m0-402.2784A146.2784 146.2784 0 1 0 743.611733 512 146.2784 146.2784 0 0 0 597.333333 365.7216z"
              fill="#1296db" p-id="5235" data-spm-anchor-id="a313x.search_index.0.i0.601f3a81BgLMWY" class="selected" />
          </svg>
          <svg t="1709439274416" class="icon icon2" viewBox="0 0 1194 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="5234" width="30" height="30">
            <path
              d="M597.333333 1024a512 512 0 1 1 512-512 512 512 0 0 1-512 512z m0-914.2784A402.2784 402.2784 0 1 0 999.611733 512 402.2784 402.2784 0 0 0 597.333333 109.7216zM597.333333 768a256 256 0 1 1 256-256 256 256 0 0 1-256 256z m0-402.2784A146.2784 146.2784 0 1 0 743.611733 512 146.2784 146.2784 0 0 0 597.333333 365.7216z"
              fill="#1296db" p-id="5235" data-spm-anchor-id="a313x.search_index.0.i0.601f3a81BgLMWY" class="selected" />
          </svg>
          <svg t="1709439274416" class="icon icon3" viewBox="0 0 1194 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="5234" width="30" height="30">
            <path
              d="M597.333333 1024a512 512 0 1 1 512-512 512 512 0 0 1-512 512z m0-914.2784A402.2784 402.2784 0 1 0 999.611733 512 402.2784 402.2784 0 0 0 597.333333 109.7216zM597.333333 768a256 256 0 1 1 256-256 256 256 0 0 1-256 256z m0-402.2784A146.2784 146.2784 0 1 0 743.611733 512 146.2784 146.2784 0 0 0 597.333333 365.7216z"
              fill="#1296db" p-id="5235" data-spm-anchor-id="a313x.search_index.0.i0.601f3a81BgLMWY" class="selected" />
          </svg>
        </div>
        <div class="loan-right">
          <div class="amount">
            <p style="font-weight:700;font-size:120%;color: #1A71FA">Please Enter The Loan Amount</p>
            <div class="num">
              <button @click="open" type="text">
                <svg t="1709441377346" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="6322" width="50" height="50">
                  <path
                    d="M761.5 928.3H228.3C156.5 928.3 98 869.8 98 798V277.6c0-71.8 58.5-130.3 130.3-130.3h248.8c23.2 0 42.1 18.8 42.1 42.1s-18.8 42.1-42.1 42.1H228.3c-25.5 0-46.2 20.7-46.2 46.2V798c0 25.5 20.7 46.2 46.2 46.2h533.2c25.5 0 46.2-20.7 46.2-46.2V501.2c0-23.2 18.8-42.1 42.1-42.1s42.1 18.8 42.1 42.1V798c-0.1 71.8-58.5 130.3-130.4 130.3z"
                    fill="#333333" p-id="6323" />
                  <path
                    d="M374.5 675.7c-11.1 0-21.8-4.4-29.7-12.3-9.1-9.1-13.6-22-12-34.8 12.3-101.9 59-198 131.6-270.5l245.2-245.2C726.2 96.2 748.4 87 772 87s45.8 9.2 62.5 25.9l60.7 60.6c34.5 34.5 34.5 90.6 0 125L650 543.8c-72.6 72.6-168.6 119.3-270.5 131.6-1.7 0.2-3.4 0.3-5 0.3zM772 171.3c-1.2 0-2.3 0.4-3 1.1L523.8 417.6c-45.1 45.1-78 101.3-95.6 162.3 61-17.6 117.2-50.5 162.3-95.6l245.2-245.2c1.7-1.7 1.7-4.4 0-6.1L775 172.4c-0.6-0.7-1.8-1.1-3-1.1z m93.5 97.6h0.2-0.2z"
                    fill="#333333" p-id="6324" />
                </svg>
              </button>
              <p style="font-weight: 700;font-size:45px;margin: 10px 0;">{{ loanAmount }}₱</p>
            </div>
          </div>

          <div class="repayment">
            <p style="font-weight:700;font-size:20px;color: #1A71FA">Loan Repayment</p>
            <div class="repayment-buts">
              <button class="r1" :class="{ 'selected': selectedMonth === 0 }" @click="month6()">
                <small>{{ limitInfo.limitmonth[0] }}&nbsp;Month</small>
              </button>
              <button class="r2" :class="{ 'selected': selectedMonth === 1 }" @click="month12()">
                <span>{{ limitInfo.limitmonth[1] }}&nbsp;Month</span>
              </button>
              <button class="r3" :class="{ 'selected': selectedMonth === 2 }" @click="month24()">
                <span>{{ limitInfo.limitmonth[2] }}&nbsp;Month</span>
              </button>
              <button class="r4" :class="{ 'selected': selectedMonth === 3 }" @click="month36()">
                <span>{{ limitInfo.limitmonth[3] }}&nbsp;Month</span>
              </button>
            </div>

            <div class="repayment-info">
              <div>
                <p>{{ amountToTheAccount }}₱</p>
                <small>Amount To The Account</small>
              </div>
              <div>
                <p>{{ monthlyRepayment }}₱</p>
                <small>Monthly Repayment</small>
              </div>
              <div>
                <p>{{ monthlyInterest }}₱</p>
                <small>Monthly interest</small>
              </div>
              <div>
                <p>{{ loanRepaymentPeriod }}</p>
                <small>Loan Repayment Period</small>
              </div>
            </div>
          </div>

          <div class="Interest">
            <p style="font-weight:700;font-size:20px;color: #1A71FA">Monthly Interest</p>
            <div class="Interest-info">
              <small style="font-weight: 700;font-size:30px">{{ this.monthlyRepayment }}₱(Monthly interest
                {{ parseFloat(lx) * 100 }}% = {{ Math.ceil((this.monthlyInterest)) }} ₱)</small>
            </div>
          </div>
          <div class="hk">
            <div class="hk-1">
              <!-- 进度条 -->
              <div class="block">
                <el-slider v-model="loanAmount" :min="limitInfo.limitmin" :max="limitInfo.limitmax"></el-slider>
              </div>
              <!-- 尺子 -->
              <div class="ruler">
                <div class="cm">
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                </div>
                <div class="cm">
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                </div>
                <div class="cm">
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                </div>
                <div class="cm">
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                </div>
                <div class="cm">
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                </div>
                <div class="cm">
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                </div>
                <div class="cm">
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                </div>
                <div class="cm">
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                </div>
                <div class="cm">
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                </div>
                <div class="cm">
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                  <div class="mm"></div>
                </div>
                <div class="cm"></div>
              </div>
              <button @click="goToApplyForALoan" class="h-bu hk-buts"
                style="color:white;font-size:20px">Apply&nbsp;lmmediately</button>
            </div>
            <div class="hk-2">
              <div class="hkxy">
                <label class="container" style="font-size:15px">
                  Please Agree
                  <a class="aaa" href="#/UserServiceAgreement">User Service Agreement</a> &nbsp;and&nbsp;
                  <a class="aaa" href="#/PolicyConditions">Policy Conditions</a>
                  <input type="checkbox" v-model="checked" />
                  <span class="checkmark" @click="gopo()"></span>
                </label>
              </div>
              <small class="sa" style="font-size:15px">Loans Are Risky, Borrowing Needs To Be Cautious. Consume
                Rationally And Avoid Overdue.</small>
            </div>
          </div>
        </div>
      </div>
      <div class="hbox"></div>
    </div>
  </div>
</template>

<script>
import MenuTop from "@/components/MenuTop.vue";
import axios from "axios";
export default {
  components: {
    MenuTop
  },
  data() {
    return {
      imgss: ["../../public/11.jpg", "../../public/22.jpg", "../../public/33.jpg", "../../public/44.jpg"],
      selectedMonth: 0,
      checked: false,
      loanAmount: 0,
      monthlyRepayment: 0,
      amountToTheAccount: 0,
      monthlyInterest: 0,
      loanRepaymentPeriod: 0,
      lx: 0.5,
      limitInfo: {
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        remark: null,
        id: 1,
        limitmin: 150000,
        limitmax: 2000000,
        limitmemo: "wwww",
        limitcs: 11,
        limitmonth: [],
        limitcsmonth: 11,
        limitserveramount: 1,
        limitserverlx: 1,
        limityqamount: 1
      },
      bor: false
    };
  },
  created() {
    this.getLimitInfo();
    ////console.log(1111)
  },
  mounted() {
    if (
      sessionStorage.getItem("aggree0") &&
      sessionStorage.getItem("aggree3")
    ) {
      this.checked = true;
    }
  },
  watch: {
    loanAmount(newValue, oldValue) {
      this.chooesMonth();
    }
  },
  methods: {
    gopo() { },
    async getLimitInfo() {
      try {
        const a = await axios.get(
          `/borrowingSys/borrowing/borro/isBorrowing?uid=${+sessionStorage.getItem(
            "token"
          )}`
        );
        //  //////console.log(a);
        if (a.data.msg != "可以借款") {
          this.bor = true;
        } else {
          this.bor = false;
        }

        const b = await axios.get(`limit/limit/uplimit`);
        this.limitInfo = b.data.data;
        this.loanAmount = this.limitInfo.limitmin;
        this.lx = this.limitInfo.limitserverlx;
        sessionStorage.setItem("month", this.limitInfo.limitmonth[0]);
        this.month6();
      } catch (error) { }
    },

    goToApplyForALoan() {
      if (1) {
      }
      if (!this.checked) {
        this.$message.error(
          "Please read and agree to the customer use agreement"
        );
        return;
      }
      if (this.bor) {
        this.$message.error("You already have a loan");
        return;
      }

      sessionStorage.setItem("LoanAmount", this.loanAmount);
      sessionStorage.setItem("Interest", this.monthlyRepayment);
      sessionStorage.setItem("LoanMonth", this.loanRepaymentPeriod);

      this.$router.push("/ApplyForALoan");
    },
    formatTooltip(val) {
      this.month6();
      return val / 100;
    },
    open() {
      this.$prompt(`Please Enter The Loan Amount`, {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        center: true

        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      })
        .then(({ value }) => {
          const regex = /^\d+$/;
          if (!regex.test(value)) {
            this.$message({
              type: "error",
              message: "Invalid input"
            });
            return;
          }
          if (Number(value) > this.limitInfo.limitmax) {
            this.loanAmount = this.limitInfo.limitmax;
            this.$message({
              type: "success",
              message: "confirm success max"
            });
          } else if (Number(value) < this.limitInfo.limitmin) {
            this.loanAmount = this.limitInfo.limitmin;
            this.$message({
              type: "success",
              message: "confirm success min"
            });
          } else {
            this.loanAmount = Number(value);
            this.$message({
              type: "success",
              message: "confirm success"
            });
          }
          this.chooesMonth();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Cancel"
          });
        });
    },
    chooesMonth() {
      if (sessionStorage.getItem("month") == "6") {
        this.month6();
      }
      if (sessionStorage.getItem("month") == "12") {
        this.month12();
      }
      if (sessionStorage.getItem("month") == "24") {
        this.month24();
      }
      if (sessionStorage.getItem("month") == "36") {
        this.month36();
      }
    },
    month6(index) {
      this.selectedMonth = 0;
      ////////console.log(index);
      this.amountToTheAccount = this.loanAmount;
      this.monthlyInterest = this.loanAmount * this.lx;
      this.monthlyRepayment =
        this.loanAmount / this.limitInfo.limitmonth[0] + this.monthlyInterest;
      this.loanRepaymentPeriod = this.limitInfo.limitmonth[0];
      this.monthlyRepayment = Math.ceil(this.monthlyRepayment);
      this.monthlyInterest = Math.ceil(this.monthlyInterest);
      sessionStorage.setItem("month", this.limitInfo.limitmonth[0]);
    },
    month12() {
      this.selectedMonth = 1;
      this.amountToTheAccount = this.loanAmount;
      this.monthlyInterest = this.loanAmount * this.lx;
      this.monthlyRepayment =
        this.loanAmount / this.limitInfo.limitmonth[1] + this.monthlyInterest;
      this.loanRepaymentPeriod = this.limitInfo.limitmonth[1];
      this.monthlyRepayment = Math.ceil(this.monthlyRepayment);
      this.monthlyInterest = Math.ceil(this.monthlyInterest);
      sessionStorage.setItem("month", this.limitInfo.limitmonth[1]);
    },
    month24() {
      this.selectedMonth = 2;
      this.amountToTheAccount = this.loanAmount;
      this.monthlyInterest = this.loanAmount * this.lx;
      this.monthlyRepayment =
        this.loanAmount / this.limitInfo.limitmonth[2] + this.monthlyInterest;
      this.loanRepaymentPeriod = this.limitInfo.limitmonth[2];
      this.monthlyRepayment = Math.ceil(this.monthlyRepayment);
      this.monthlyInterest = Math.ceil(this.monthlyInterest);
      sessionStorage.setItem("month", this.limitInfo.limitmonth[2]);
    },
    month36() {
      this.selectedMonth = 3;
      this.amountToTheAccount = this.loanAmount;
      this.monthlyInterest = this.loanAmount * this.lx;
      this.monthlyRepayment =
        this.loanAmount / this.limitInfo.limitmonth[3] + this.monthlyInterest;
      this.loanRepaymentPeriod = this.limitInfo.limitmonth[3];
      this.monthlyRepayment = Math.ceil(this.monthlyRepayment);
      this.monthlyInterest = Math.ceil(this.monthlyInterest);
      sessionStorage.setItem("month", this.limitInfo.limitmonth[3]);
    }
  }
};
</script>
<style scoped>
.img-box {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  /* border: 1px solid red; */
  align-items: center;
  margin-top: 10px;
}

.imgs-0 {
  width: 15%;
  /* border: 1px solid red; */
}

.imgs-0 img {
  width: 100%;
  object-fit: cover;
}

.imgs-0 span {
  text-align: center;
}

.imgs-2 {
  width: 6%;
  height: 6%;
  /* border: 1px solid red; */
}

.imgs-2 img {
  width: 50%;
  height: 50%;
  object-fit: contain;

}

/* .img2{
      width: 30px;
      object-fit: cover;
} */
.sa {
  width: 100%;
  height: auto;
  padding-bottom: 0px;
}

.ruler {
  position: relative;
  width: 100%;
  margin: 5px auto 20px auto;
  height: 14px;
}

.ruler .cm,
.ruler .mm {
  position: absolute;
  border-left: 1px solid #555;
  height: 14px;
  width: 10%;
}

.ruler .cm:after {
  position: absolute;
  bottom: -15px;
  font: 11px/1 sans-serif;
}

.ruler .mm {
  height: 5px;
}

.ruler .mm:nth-of-type(5) {
  height: 10px;
}

.ruler .cm:nth-of-type(1) {
  left: 0%;
}

.ruler .cm:nth-of-type(1):after {
  content: "0";
  left: -5%;
}

.ruler .cm:nth-of-type(2) {
  left: 10%;
}

.ruler .cm:nth-of-type(2):after {
  content: "1";
  left: -5%;
}

.ruler .cm:nth-of-type(3) {
  left: 20%;
}

.ruler .cm:nth-of-type(3):after {
  content: "2";
  left: -5%;
}

.ruler .cm:nth-of-type(4) {
  left: 30%;
}

.ruler .cm:nth-of-type(4):after {
  content: "3";
  left: -5%;
}

.ruler .cm:nth-of-type(5) {
  left: 40%;
}

.ruler .cm:nth-of-type(5):after {
  content: "4";
  left: -5%;
}

.ruler .cm:nth-of-type(6) {
  left: 50%;
}

.ruler .cm:nth-of-type(6):after {
  content: "5";
  left: -5%;
}

.ruler .cm:nth-of-type(7) {
  left: 60%;
}

.ruler .cm:nth-of-type(7):after {
  content: "6";
  left: -5%;
}

.ruler .cm:nth-of-type(8) {
  left: 70%;
}

.ruler .cm:nth-of-type(8):after {
  content: "7";
  left: -5%;
}

.ruler .cm:nth-of-type(9) {
  left: 80%;
}

.ruler .cm:nth-of-type(9):after {
  content: "8";
  left: -5%;
}

.ruler .cm:nth-of-type(10) {
  left: 90%;
}

.ruler .cm:nth-of-type(10):after {
  content: "9";
  left: -5%;
}

.ruler .cm:nth-of-type(11) {
  left: 100%;
}

.ruler .cm:nth-of-type(11):after {
  content: "10";
  left: -5%;
}

.ruler .mm:nth-of-type(1) {
  left: 10%;
}

.ruler .mm:nth-of-type(2) {
  left: 20%;
}

.ruler .mm:nth-of-type(3) {
  left: 30%;
}

.ruler .mm:nth-of-type(4) {
  left: 40%;
}

.ruler .mm:nth-of-type(5) {
  left: 50%;
}

.ruler .mm:nth-of-type(6) {
  left: 60%;
}

.ruler .mm:nth-of-type(7) {
  left: 70%;
}

.ruler .mm:nth-of-type(8) {
  left: 80%;
}

.ruler .mm:nth-of-type(9) {
  left: 90%;
}

.ruler .mm:nth-of-type(10) {
  left: 100%;
}

#mentionme {
  text-align: center;
  margin-top: 10%;
}

::v-deep .el-slider__button {
  height: 30px;
  width: 30px;
}

::v-deep .el-slider__bar {
  height: 15px;
  background-image: linear-gradient(90deg, #44dcf9 0%, #2ca1fa 100%);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
}

::v-deep .el-slider__button-wrapper {
  height: 36px;
  width: 36px;
  z-index: 1001;
  top: -10px;
}

::v-deep .el-slider__runway {
  width: 100%;
  height: 15px;
  margin: 16px 0;
  background-color: #e4e7ed;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
}

.aaa {
  margin-top: 20px;
}

.hkxy {
  width: 100%;
  padding: 30px 0 20px 0;
}

/* 容器 */
.container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 10px;
}

/* 隐藏浏览器的默认复选框 */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* 创建自定义复选框 */
.checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.container a {
  padding: 30px 0;
}

/* 在鼠标悬停时，添加灰色背景色 */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* 当复选框被选中时，添加一个蓝色背景 */
.container input:checked~.checkmark {
  background-color: #2196f3;
}

/* 创建复选标记/指示器（未选中时隐藏）*/
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* 选中时显示复选标记 */
.container input:checked~.checkmark:after {
  display: block;
}

/* 设置复选标记/指示器的样式 */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ppp {
  margin-top: 10px;
  padding: 10px 80px;
  font-size: 30px;
}

.english {
  width: 90%;
  display: flex;
  justify-content: right;
}

.customClass {
  width: 50px;
}

/* 
.r1:hover {
  background-color: #214d9e;
  color: white;
}
.r2:hover {
  background-color: #214d9e;
  color: white;
}
.r3:hover {
  background-color: #214d9e;
  color: white;
}
.r4:hover {
  background-color: #214d9e;
  color: white;
}

.h-bu:hover {
  background-color: #214d9e;
  color: white;
} */
.hk-buts {
  width: 60%;
  padding: 10px 0 10px 0;
  border-radius: 30px;
  background-color: #00264b;
  margin-top: 20px;
  border: none;
  color: white;
}

.hk-1 {
  width: 80%;
}

.hk-2 {
  width: 100%;
}

.hk {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
}

.Interest {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #00264b;
  margin-top: 20px;
}

.repayment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.repayment-buts {
  width: 100%;
  border-bottom: 1px solid #00264b;
  display: flex;
  justify-content: space-between;
}

.repayment-buts button {
  width: 24%;
  padding: 3px 0;
  margin: 0px 5px;
  overflow: hidden;
}

.repayment-buts span {
  font-size: 16px;
}

.repayment-info {
  width: 100%;
  display: flex;
  justify-content: center;
}

.repayment-info div {
  width: 25%;
  margin: 0px 5px;
  overflow: hidden;
}

.repayment-info div small {
  font-weight: 600;
  font-size: 20px;
}

.repayment-info div p {
  font-weight: 600;
  font-size: 25px;
  color: black;
}

.repayment button {
  width: 22%;
  border: none;
  border-radius: 10px;
  font-size: 20px;
}

.num {
  display: flex;
  width: 100%;
} 
.num button {
  border: none;
  width: 50px;
  height: auto;
  background-color: white;
}

button {
  cursor: pointer;
}

.num p {
  font-size: 30px;
}

.amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #00264b;
}

.icon {
  width: 80%;
}

.icon1 {
  position: absolute;
  left: 15%;
}

.icon2 {
  position: absolute;
  left: 15%;
  top: 40%;
}

.icon3 {
  position: absolute;
  left: 15%;
  top: 80%;
}

.loan-right {
  width: 95%;
}

.loan-left {
  width: 5%;
  height: 100%;
  position: relative;
}

.loan-left-line {
  position: absolute;
  width: 10%;
  height: 100%;
  left: 50%;
  background-color: rgb(212, 224, 224);
}

.loan {
  display: flex;
  width: 100%;
  height: 800px;
}

p {
  word-wrap: break-word;
  word-break: break-all;
}

.line {
  background-color: #00264b;
  width: 2px;
  height: 60px;
}

.apply-1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
  border-top: 1px solid #00264b;
  border-bottom: 1px solid #00264b;
  padding: 10px 0 10px 0;
  margin-top: 10px;
}

.apply {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 10px 0 10px 0;
}

.home {
  width: 100%;
  position: relative;
}

.box-info {
  width: 100%;
  height: 100px;
}

.ab-4 {
  background-color: #00264b;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ab-4-1 {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.ab-4-2 {
  margin-top: 20px;
}

.ab-4-1-1 {
  width: 300px;
  padding: 5px;
  background-color: #00264b;
  display: flex;
  justify-content: space-around;
  color: white;
}

.ab-4-1-1 {
  width: 500px;
}

.ab-box {
  background-color: #f0f0f7;
  padding: 50px;
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.ab {
  width: 450px;
  height: 200px;
  background-color: #00264b;
  position: relative;
}

.ab img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.ab-text {
  position: absolute;
  width: 100%;
  top: 20%;
  color: white;
}

.new-1-1 {
  border: none;
  background-color: #808199;
  width: 250px;
  border-radius: 10px;
  margin-top: 10px;
}

.img {
  width: 250px;
  height: 200px;
}

.img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.news {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.news-text {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.news-1 {
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.item_box {
  width: 100%;
  height: auto;
  position: relative;
}

.item {
  position: absolute;
  width: 100%;
  top: 20%;
}

.item p {
  font-size: 60px;
  color: white;
}

.item button {
  font-size: 20px;
  color: white;
  border: none;
  background-color: #00264b;
  border-radius: 30px;
  padding: 10px 30px 10px 30px;
}

.imgs {
  width: 100%;
  height: 200px;
  object-fit: 100%;
}

.box-info {
  position: relative;
  margin-top: -5%;
}

.notice {
  width: 100%;
  height: 40px;
  margin: 100px auto;
  overflow: hidden;
}

.icon-notice {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 2%;
}

.notice__inner {
  animation: roll 36s linear infinite;
  margin-top: 0;
}

.notice__item {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
}

@media screen and (max-width: 500px) {
  .notice {
    height: 80px;
  }

  .notice__item {
    height: 80px;
  }

  .icon-notice {
    display: none;
  }

  .repayment-info div small {
    font-weight: 600;
    font-size: 15px;
  }

  .repayment-info div p {
    font-weight: 600;
    font-size: 15px;
    color: red;
  }
}

@media screen and (max-width: 380px) {
  .notice {
    height: 80px;
  }

  .notice__item {
    height: 80px;
  }

  .icon-notice {
    display: none;
  }

  .repayment-info div small {
    font-weight: 600;
    font-size: 12px;
  }

  .repayment-info div p {
    font-weight: 600;
    font-size: 12px;
    color: red;
  }
}

@media screen and (max-width: 230px) {
  .notice {
    height: 120px;
  }

  .notice__item {
    height: 120px;
  }

  .icon-notice {
    display: none;
  }
}

@keyframes roll {
  0% {
    margin-top: 0;
  }

  4% {
    margin-top: 0;
  }

  8% {
    margin-top: 0;
  }

  12% {
    margin-top: -40px;
  }

  16% {
    margin-top: -40px;
  }

  20% {
    margin-top: -80px;
  }

  24% {
    margin-top: -80px;
  }

  28% {
    margin-top: -120px;
  }

  32% {
    margin-top: -120px;
  }

  36% {
    margin-top: -160px;
  }

  40% {
    margin-top: -160px;
  }

  44% {
    margin-top: -200px;
  }

  48% {
    margin-top: -200px;
  }

  52% {
    margin-top: -240px;
  }

  56% {
    margin-top: -240px;
  }

  60% {
    margin-top: -200px;
  }

  64% {
    margin-top: -200px;
  }

  68% {
    margin-top: -160px;
  }

  72% {
    margin-top: -160px;
  }

  76% {
    margin-top: -120px;
  }

  80% {
    margin-top: -120px;
  }

  84% {
    margin-top: -80px;
  }

  88% {
    margin-top: -80px;
  }

  92% {
    margin-top: -40px;
  }

  96% {
    margin-top: -40px;
  }

  100% {
    margin-top: 0;
  }
}

.apply {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 0 0 10px 0;
  margin-top: 0%;
}

.r1,
.r2,
.r3,
.r4 {
  /* 默认的灰色样式 */
  background-color: rgba(198, 202, 205, 0.747);
}

.selected {
  /* 选中时的蓝色样式 */
  background-color: #00264b;
  color: white;
}
</style>
