import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },

  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
  },
  
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue'),
  },
  {
    path: '/loao',
    name: 'loao',
    component: () => import(/* webpackChunkName: "about" */ '../views/loao.vue'),
  },
 
  {
    path: '/myloan',
    name: 'myloan',
    component: () => import('../views/Myloan.vue'),
  },
  {
    path: '/Myrepayment',
    name: 'Myrepayment',
    component: () => import('../views/Myrepayment.vue'),
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: () => import('../views/Transaction.vue'),
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/success.vue'),
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/setUp',
    name: 'setUp',
    component: () => import('../views/Setup.vue'),
  },
  {
    path: '/idInformation',
    name: 'idInformation',
    component: () => import('../views/IDinformation.vue'),
  },
  {
    path: '/repayment',
    name: 'Repayment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Repayment.vue'),
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import(/* webpackChunkName: "about" */ '../views/wallet.vue'),
  },
  {
    path: '/MyProfileviews',
    name: 'MyProfileviews',
    component: () => import(/* webpackChunkName: "about" */ '../views/MyProfileviews.vue'),
  },
  
  {
    path: '/Rbc',
    name: 'Rbc',
    component: () => import(/* webpackChunkName: "about" */ '../views/Rbc.vue'),
  },
  {
    path: '/Information',
    name: 'Informationbc',
    component: () => import(/* webpackChunkName: "about" */ '../views/Information.vue'),
  },
  {
    path: '/ApplyForALoan',
    name: 'ApplyForALoan',
    component: () => import(/* webpackChunkName: "about" */ '../views/ApplyForALoan.vue'),
  },
  {
    path: '/xy',
    name: 'xy',
    component: () => import(/* webpackChunkName: "about" */ '../views/xy.vue'),
  },
  {
    path: '/constract',
    name: 'constract',
    component: () => import(/* webpackChunkName: "about" */ '../views/constract.vue'),
  },
  
  {
    path: '/Signature',
    name: 'Signature',
    component: () => import(/* webpackChunkName: "about" */ '../views/Signature.vue'),
  },
  {
    path: '/PolicyConditions',
    name: 'PolicyConditions',
    component: () => import(/* webpackChunkName: "about" */ '../views/PolicyConditions.vue'),
  },

  {
    path: '/UserServiceAgreement',
    name: 'UserServiceAgreement',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserServiceAgreement.vue'),
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import(/* webpackChunkName: "about" */ '../views/messages.vue'),
  },
  
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/Account.vue'),
  },
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  
  next()
})


export default router
